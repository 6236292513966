<template>
  <v-container id="innovations" class="ma-auto" fluid>
    <v-row class="pa-0 ma-0 main">
      <v-col cols="11" md="10" lg="8" class="ma-auto pa-0 px-4">
        <v-row data-aos="fade-down" class="pa-0 ma-0">
          <v-col cols="12" class="text-center title custom-accent--text">
            <v-icon class="text-h2 custom-accent--text">mdi-head-lightbulb</v-icon>
            <h1 class="text-h6 pa-0 ma-0">{{$t("innovationtitle")}}</h1>
          </v-col>
          <v-col cols="12" md="6" class="custom-secondary--text pa-0 ma-0 px-4">
            <div v-html="$i18n.locale=='en'?column1en:column1fr"></div>
          </v-col>
          <v-col cols="12" md="6" class="custom-secondary--text pa-0 ma-0 px-4">
            <div v-html="$i18n.locale=='en'?column2en:column2fr"></div>
            <v-img :src="storage_url+innovations_path+'innovation-bg.jpg'" :alt="$t('innovation1alt')">
              <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="custom-secondary" 
                    ></v-progress-circular>
                  </v-row>
                </template>
            </v-img>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

export default {
  name: "Innovations",

  data() {
    return {
      storage_url: process.env.VUE_APP_STORAGEURL,
      innovations_path: "solutions/innovations/images/",
      column1en: `<p class="caption">
              SoftSim Technologies Inc. has had a  focus on the development of technologies that improve scientific research, more specifically medical. In recent years, research has shown that the scientific research industry is increasingly moving towards the utilizing of latest advancements in Blockchain, Deep Learning, AR and VR using the powerful GPU processing power.
            </p>
            <p class="caption">
              As such, SoftSim Technologies conducted its own research, focusing on the extraction of data from the accessible Big Data reserves (available on the World Wide Web)using deep learning and AI techniques. Extracted data is then classified and synthesized to researchers. In addition, the research also focused on combining deep learning algorithms and blockchain smart contracts to manage confidential data. Due to the fact that blockchain infrastructure and technology was designed for bitcoin and crypto-currency, it needs to be adapted for management of smart contracts that are not based necessarily on financial compensations.
            </p>
            <p class="caption">
              During this research, some of the challenges faced included: measuring the accuracy of the data sources. In addition, some uncertainties faced included (listed below a few):
            </p>
            <ul class="caption">
              <li>Which cryptographic algorithms to use to secure data ?</li>
              <li>What are the most efficient methods to break down and classify the medical data in different blockchains based on type?</li>
              <li>Which techniques and methods to use to implement the smart contracts</li>
            </ul>
            <p class="caption">
              Uncertainties were managed through continued building and testing of new prototypes ( in order to enhance the performance and achieve the required level of data security), technical experimentations (such as testing of OpenSemantic Platform, testing methods and encryption algorithms) and development/ testing of  prototyped smart contracts (to be used for a typical clinical trial life cycle). 
            </p>
            <p class="caption">
              SoftSim advanced in this technical evaluation considering several key aspects, including but not limited to: the continuous improvement of the performance and capabilities of this prototype within our core technology set.  In addition, we conducted analysis, testing and problem resolutions.
            </p>
            <p class="caption">
              Our experiment led us to especially focus on the advancements Blockchain supporting Electronic Medical Records would bring: agility and efficiency, security, simplicity, scalability, improved data quality.
            </p>
            <p class="caption">
              <b><i>AppHolo FAQ Design Document</i></b>
            </p>
            <p class="caption">
              <i>Core mechanics</i>
            </p>
            <p class="caption">
              It will start with an overview of the AppHolo project and the control schemes for the FAQ application. After which the user will be in a room where they can click on various items to see and hear a more in depth explanation of that piece of the AppHolo project. This explanation can bring the user outside the room.
            </p>
            <p class="caption">
              <b><i>Control schemes</i></b>
            </p>
            <p class="caption">
              <b>The basic control scheme is to:</b>
            </p>
            <ul class="caption">
              <li class="pb-2">Click on an object to show animation explanations associated with clicked object (index finger button)</li>
              <li class="pb-2">Alternatively, a user can verbally ask questions about the projects: The start of a question can be created by a wake word or an unspecified button press (maybe X button).</li>
              <li class="pb-2">Hover the cursor over an object to see the text description of the object.</li>
              <li class="pb-2">Use an unspecified button (maybe Y button) to teleport to locations in the room.</li>
              <li class="pb-2">To ensure that the user can skip the current explanation, the user can press a currently undefined button (maybe B button) to skip the explanation and go back to the room.</li>
            </ul>`,
      column1fr: `<p class="caption">
              SoftSim Technologies Inc. a mis l'accent sur le développement de technologies qui améliorent la recherche scientifique, plus spécifiquement médicale. Ces dernières années, les recherches ont montré que l'industrie de la recherche scientifique s'oriente de plus en plus vers l'utilisation des dernières avancées en matière de Blockchain, Deep Learning, AR et VR en utilisant la puissante puissance de traitement des GPU.
            </p>
            <p class="caption">
              Ainsi, SoftSim Technologies a mené ses propres recherches, en se concentrant sur l'extraction de données à partir des réserves de Big Data accessibles (disponibles sur le World Wide Web) en utilisant des techniques d'apprentissage profond et d'IA. Les données extraites sont ensuite classifiées et synthétisées pour les chercheurs. En outre, la recherche s'est également concentrée sur la combinaison d'algorithmes d'apprentissage approfondi et de contrats intelligents en chaîne de blocs pour gérer les données confidentielles. Étant donné que l'infrastructure et la technologie de la chaîne de blocs ont été conçues pour les bitcoins et la crypto-monnaie, elles doivent être adaptées à la gestion de contrats intelligents qui ne sont pas nécessairement basés sur des compensations financières.            </p>
            <p class="caption">
              Au cours de cette recherche, certains des défis à relever ont consisté à mesurer la précision des sources de données. En outre, certaines incertitudes ont été rencontrées (énumérées ci-dessous) :
            </p>
            <ul class="caption">
              <li>Quels algorithmes cryptographiques utiliser pour sécuriser les données</li>
              <li>Quelles sont les méthodes les plus efficaces pour décomposer et classer les données médicales en différentes chaînes de blocs en fonction de leur type.</li>
              <li>Quelles sont les techniques et méthodes à utiliser pour mettre en œuvre les contrats intelligents</li>
            </ul>
            <p class="caption">
              Les incertitudes ont été gérées par la construction et l'essai continus de nouveaux prototypes (afin d'améliorer les performances et d'atteindre le niveau requis de sécurité des données), des expérimentations techniques (telles que l'essai de la plate-forme OpenSemantic, des méthodes d'essai et des algorithmes de cryptage) et le développement et l'essai de prototypes de contrats intelligents (à utiliser pour un cycle de vie d'essai clinique typique). 
            </p>
            <p class="caption">
              SoftSim a progressé dans cette évaluation technique en tenant compte de plusieurs aspects clés, notamment : l'amélioration continue des performances et des capacités de ce prototype au sein de notre ensemble de technologies de base.  En outre, nous avons effectué des analyses, des essais et des résolutions de problèmes.
            </p>
            <p class="caption">
              Notre expérience nous a amenés à nous concentrer tout particulièrement sur les avancées que la chaîne de blocs supportant les dossiers médicaux électroniques apporterait : agilité et efficacité, sécurité, simplicité, extensibilité, amélioration de la qualité des données.
            </p>
            <p class="caption">
              <b><i>Document de conception de la FAQ AppHolo</i></b>
            </p>
            <p class="caption">
              <i>Mécanique de base</i>
            </p>
            <p class="caption">
              Il commencera par un aperçu du projet AppHolo et des schémas de contrôle de l'application FAQ. Ensuite, l'utilisateur se trouvera dans une pièce où il pourra cliquer sur différents éléments pour voir et entendre une explication plus approfondie de cette partie du projet AppHolo. Cette explication peut amener l'utilisateur à sortir de la pièce.
            </p>
            <p class="caption">
              <b><i>Schémas de contrôle</i></b>
            </p>
            <p class="caption">
              <b>Le schéma de contrôle de base est de :</b>
            </p>
            <ul class="caption">
              <li class="pb-2">Cliquer sur un objet pour afficher les explications de l'animation associée à l'objet cliqué (bouton de l'index)</li>
              <li class="pb-2">Un utilisateur peut également poser verbalement des questions sur les projets : Le début d'une question peut être créé par un mot de réveil ou une pression sur un bouton non spécifié (peut-être le bouton X)</li>
              <li class="pb-2">Passez le curseur sur un objet pour voir la description textuelle de l'objet.</li>
              <li class="pb-2">Utilisez un bouton non spécifié (peut-être le bouton Y) pour vous téléporter à des endroits dans la pièce.</li>
              <li class="pb-2">Pour que l'utilisateur puisse sauter l'explication en cours, il peut appuyer sur un bouton actuellement non défini (peut-être le bouton B) pour sauter l'explication et retourner dans la salle.</li>
            </ul>`,
      column2en: `<p class="caption">
              <i>Presentation</i>
            </p>
            <p class="caption">
              First a short presentation overview of AppHolo is played, which the user can skip and at the end give an explanation of the core mechanics, and what questions can be answered.  The user is then placed into a room with various objects representing various aspects of the AppHolo project. The user can interact with these objects to gain further understanding of the AppHolo project.
            </p>
            <p class="caption">
              <b><i>Data for Axcess 1</i></b>
            </p>
            <p class="caption">
              SoftSim Technologies needs training data to train an AI for identifying what information is public knowledge in company and government documents. SoftSim Technologies is building an AI system to help users identify information in documents that are potentially classified and what information is public knowledge. To help identify information that is public knowledge SoftSim is planning on using an AI, however this AI first needs to be trained on such a task. This document's aim is to secure such training data. In particular, SoftSim wishes to record the discovery process of finding information that is public knowledge, particularly what document data is linked to what found data. 
            </p>
            <p class="caption">
              <i>Areas of Use</i>
            </p>
            <p class="caption">
              The AI can have additional benefits and uses outside of finding public information in documents. The AI essentially finds information links between paragraphs and documents. This information linkage can have various uses. These information links can help create a graph between various documents within an organization. Documents with a similar topic (possibly used for different audiences and purposes) will have high information linkage. This can then be used to meaningfully group documents together on a topic.
            </p>
            <p class="caption">
              This linking of information across documents can also help develop strategic planning of future projects. Information linkage in documents across different departments can help identify common assets and interests across these departments, and thus affect strategic planning of projects involving these assets and interests. If a project builds upon assets covered in several different documents, information linkages between these documents can give a clearer understanding of these assets. A greater understanding of these assets can also help reduce duplicate tasks for the project.
            </p>
            <p class="caption">
              These information linkages can also help a user find additional information on a topic. For example, if there is an information link between two documents and the user is reading one document, the information linkage between the documents can lead the user to new information within the second document.
            </p>`,
      column2fr: `<p class="caption">
              <i>Présentation</i>
            </p>
            <p class="caption">
              Tout d'abord, une courte présentation d'AppHolo est diffusée, que l'utilisateur peut sauter et à la fin, donner une explication des mécanismes de base et des questions auxquelles on peut répondre.  L'utilisateur est ensuite placé dans une salle avec divers objets représentant divers aspects du projet AppHolo. L'utilisateur peut interagir avec ces objets pour mieux comprendre le projet AppHolo.
            </p>
            <p class="caption">
              <b><i>Données pour Axcess 1</i></b>
            </p>
            <p class="caption">
              SoftSim Technologies a besoin de données de formation pour former une IA à l'identification des informations qui sont de notoriété publique dans les documents des entreprises et du gouvernement. SoftSim Technologies est en train de construire un système d'IA pour aider les utilisateurs à identifier les informations contenues dans les documents qui sont potentiellement classifiés et quelles informations sont de notoriété publique. Pour aider à identifier les informations qui sont de notoriété publique, SoftSim prévoit d'utiliser une IA, mais cette IA doit d'abord être formée à cette tâche. L'objectif de ce document est de sécuriser les données de cette formation. En particulier, SoftSim souhaite enregistrer le processus de découverte des informations qui sont de notoriété publique, notamment quelles données du document sont liées à quelles données trouvées.  
            </p>
            <p class="caption">
              <i>Domaines d'utilisation</i>
            </p>
            <p class="caption">
              L'IA peut avoir des avantages et des utilisations supplémentaires en dehors de la recherche d'informations publiques dans des documents. L'IA permet essentiellement de trouver des liens d'information entre les paragraphes et les documents. Ces liens d'information peuvent avoir diverses utilisations. Ces liens d'information peuvent aider à créer un graphique entre divers documents au sein d'une organisation. Les documents ayant un sujet similaire (éventuellement utilisés pour des publics et des objectifs différents) auront un niveau élevé de liens d'information. Cela peut ensuite être utilisé pour regrouper de manière significative des documents sur un même sujet. 
            </p>
            <p class="caption">
              Ce lien d'information entre les documents peut également aider à développer la planification stratégique de futurs projets. Les liens entre les informations contenues dans les documents des différents services peuvent aider à identifier les atouts et les intérêts communs à ces services et donc à influencer la planification stratégique des projets impliquant ces atouts et ces intérêts. Si un projet s'appuie sur des actifs couverts dans plusieurs documents différents, les liens d'information entre ces documents peuvent donner une meilleure compréhension de ces actifs. Une meilleure compréhension de ces actifs peut également contribuer à réduire les tâches redondantes pour le projet.
            </p>
            <p class="caption">
              Ces liens d'information peuvent également aider un utilisateur à trouver des informations supplémentaires sur un sujet. Par exemple, s'il existe un lien d'information entre deux documents et que l'utilisateur lit un document, le lien d'information entre les documents peut l'amener à trouver de nouvelles informations dans le deuxième document.
            </p>`,
    };
  },
  components: {
  },
  created() {
  },
  mounted() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
  },
};
</script>
<style scoped>
@import url('../../assets/styles/innovations.css');
</style>